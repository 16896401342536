import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Modal from "react-modal";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import moment from "moment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { API } from "../../constant/api";
import TooltipComponent from "../Tooltip/Tooltip";
const MySwal = withReactContent(Swal);

const CancelObj = {
  request_received: "Request Received",
  accepted: "Accepted",
  rejected: "Rejected",
  refund: "Refund",
};

const showNextButtonArray = [
  "order_placed",
  "order_confirmed",
  "dispatched",
  "shipped",
  "in_transit",
  "delivered",
  "rejected",
];

const ViewOne = (props) => {
  const { id } = props.match.params;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const [orders, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [orderAddress, setOrderAddress] = useState();

  const [nextOrderStatus, setNextOrderStatus] = useState({
    key: "Order Confirmed",
    value: null,
  });

  const [cancelItemsModal, setCancelItemsModal] = useState([]);
  const [cancelModalType, setCancelModalType] = useState(null);
  const [cancelReasons, setCancelReasons] = useState([]);
  const [showCancelReasonModal, setShowCancelReasonModal] = useState(false);
  const [selectReason, setSelectedReason] = useState(null);
  const [cancelOtherReasonSelected, setCancelOtherReasonSelected] = useState({
    status: false,
    reason: "",
    showError: false,
  });

  const [isOpenRejectCancelRequestModal, setIsOpenRejectCancelRequestModal] =
    useState(false);

  const [rejectCancelRequestModalType, setRejectCancelRequestModalType] =
    useState(null);

  const [rejectCancelRequestItemId, setRejectCancelRequestItemId] =
    useState(null);

  const [rejectRequestModalType, setRejectRequestModalType] = useState(null);

  const [rejectReason, setRejectReason] = useState({
    reason: "",
    showError: false,
  });

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [refundData, setRefundData] = useState({
    type: null,
    refundType: null,
    id: null,
    item_id: null,
  });

  const [orderStatusType, setOrderStatusType] = useState("");

  const { request: requestStatus, response: responseStatus } = useRequest();

  const { response: responseOrderData, request: requestOrderData } =
    useRequest();

  const { response: responseCancelReasons, request: requestCancelReasons } =
    useRequest();

  const { response: responseCancel, request: requestCancel } = useRequest();

  const { response: responseCancelOrder, request: requestCancelOrder } =
    useRequest();
  const { response: responseCancelItem, request: requestCancelItem } =
    useRequest();

  const {
    response: responseItemCancelRequestAction,
    request: requestItemCancelRequestAction,
  } = useRequest();

  const {
    response: responseOrderCancelRequestAction,
    request: requestOrderCancelRequestAction,
  } = useRequest();

  const {
    response: responseOrderStatusUpdate,
    request: requestOrderStatusUpdate,
  } = useRequest();

  const { response: responseRefundAmount, request: requestRefundAmount } =
    useRequest();

  const { response: responseConfirmPickup, request: requestConfirmPickup } =
    useRequest();

  const { date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  useEffect(() => {
    requestOrderData("GET", `order/${id}`);
    requestCancelReasons("GET", "master/by-code/cancel-reason");

    document.title = "View Order - Single Vendor";
  }, []);

  useEffect(() => {
    if (responseOrderData) {
      const { orderDetails } = responseOrderData;
      //console.log("od", orderDetails);
      setOrder(orderDetails);
      setOrderAddress(JSON.parse(orderDetails.order_address));

      setOrderItems(
        orderDetails?.orderItems?.length ? orderDetails?.orderItems : []
      );
      if (orderDetails?.status?.length > 0) {
        const next = orderDetails.status.find((d) => d.is_active == 0);

        if (next) {
          setNextOrderStatus({
            key: next.status,
            value: next.order_status,
          });
        } else {
          setNextOrderStatus({
            key: null,
            value: "",
          });
        }
      }
    }
  }, [responseOrderData]);

  useEffect(() => {
    if (responseCancelReasons) {
      const { data } = responseCancelReasons;
      setCancelReasons(data.map((data) => ({ code: data.code, id: data.id })));
    }
  }, [responseCancelReasons]);

  useEffect(() => {
    if (responseCancel) {
      toast.success(responseCancel.message);
      requestOrderData("GET", `order/single/${id}`);
    }
  }, [responseCancel]);

  useEffect(() => {
    if (responseStatus) {
      toast.success(responseStatus.message);
      requestOrderData("GET", `order/single/${id}`);
    }
  }, [responseStatus]);

  useEffect(() => {
    if (responseCancelItem) {
      toast.success(responseCancelItem.message);
      requestOrderData("GET", `order/${id}`);
      showCancelReasonModalCloseHandler();
    }
  }, [responseCancelItem]);

  useEffect(() => {
    if (responseConfirmPickup) {
      toast.success(responseConfirmPickup.message);
      requestOrderData("GET", `order/${id}`);
    }
  }, [responseConfirmPickup]);

  useEffect(() => {
    if (responseItemCancelRequestAction) {
      toast.success(responseItemCancelRequestAction.message);
      requestOrderData("GET", `order/${id}`);
      rejectCancelRequestModalCloseHandler();
    }
  }, [responseItemCancelRequestAction]);

  useEffect(() => {
    if (responseOrderCancelRequestAction) {
      toast.success(responseOrderCancelRequestAction.message);
      requestOrderData("GET", `order/${id}`);
      rejectCancelRequestModalCloseHandler();
    }
  }, [responseOrderCancelRequestAction]);

  useEffect(() => {
    if (responseOrderStatusUpdate) {
      toast.success(responseOrderStatusUpdate.message);
      requestOrderData("GET", `order/${id}`);
    }
  }, [responseOrderStatusUpdate]);

  useEffect(() => {
    if (responseCancelOrder) {
      toast.success(responseCancelOrder.message);
      requestOrderData("GET", `order/${id}`);
      showCancelReasonModalCloseHandler();
    }
  }, [responseCancelOrder]);

  useEffect(() => {
    if (responseRefundAmount) {
      const { message } = responseRefundAmount;
      toast.success(message);
      closeRefundModalHandler();
      setValue("refund_amount", "");
      requestOrderData("GET", `order/${id}`);
    }
  }, [responseRefundAmount]);

  const handleStatusChange = (type, itemId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change status of this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("itemId",itemId)
        // console.log("type",type)
        requestStatus("PUT", "order/order-status", {
          itemId,
          type: type,
          isProcessing: type == "confirmed" ? true : false,
          orderId: id,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const handleRejectStatus = (type, itemId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change status of this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        reasonHandler(type, itemId);
      } else if (result.isDismissed) {
      }
    });
  };

  const reasonHandler = async (type, itemId) => {
    const { value: text, dismiss } = await Swal.fire({
      input: "textarea",
      inputLabel: "Reason",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Reason is required!";
        }
      },
    });

    if (text) {
      requestStatus("PUT", "order/order-status", {
        itemId,
        type: type,
        reason: text,
        orderId: id,
      });
    } else if (dismiss === Swal.DismissReason.cancel) {
      // Handle cancel button click if needed
    }
  };

  const cancelOrderHandler = () => {
    if (
      cancelOtherReasonSelected.status &&
      !cancelOtherReasonSelected.reason &&
      cancelOtherReasonSelected.reason.trim() == ""
    ) {
      setCancelOtherReasonSelected((prev) => ({ ...prev, showError: true }));
      return;
    } else if (
      selectReason == null &&
      cancelOtherReasonSelected.status == false
    ) {
      setCancelOtherReasonSelected((prev) => ({ ...prev, showError: true }));

      return;
    }

    if (cancelModalType == "order") {
      requestCancelOrder("POST", "order/cancel", {
        id,
        reason_id: selectReason,
        reason: cancelOtherReasonSelected.reason,
      });
    } else if (cancelModalType == "item") {
      const itemId = cancelItemsModal[0].id;
      requestCancelItem("POST", "order/cancel-item", {
        id: itemId,
        order_id: id,
        reason_id: selectReason,
        reason: cancelOtherReasonSelected.reason,
      });
    }
  };

  const itemCancalRequestActionHadnler = (id, status, type) => {
    if (status == "accepted") {
      MySwal.fire({
        title: "Are you sure?",
        text: "Want to cancel this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change it",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (type == "cancel") {
            requestItemCancelRequestAction("POST", "order/item-action", {
              id,
              status,
            });
          } else if (type == "return") {
            requestItemCancelRequestAction("POST", "order/item-return-action", {
              id,
              status,
            });
          }
        } else if (result.isDismissed) {
        }
      });
    } else if (status == "rejected") {
      setRejectRequestModalType(type);
      setIsOpenRejectCancelRequestModal(true);
      setRejectCancelRequestModalType("item");
      setRejectCancelRequestItemId(id);
    }
  };

  const orderCancalRequestActionHadnler = (status, type) => {
    if (status == "accepted") {
      MySwal.fire({
        title: "Are you sure?",
        text: `Want to ${
          type == "cancel" ? "cancel this order" : "accept return request"
        }?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change it",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (type == "cancel") {
            requestOrderCancelRequestAction("POST", "order/order-action", {
              id,
              status,
            });
          } else if (type == "return") {
            requestOrderCancelRequestAction(
              "POST",
              "order/order-return-action",
              {
                id,
                status,
              }
            );
          }
        } else if (result.isDismissed) {
        }
      });
    } else if (status == "rejected") {
      setRejectRequestModalType(type);
      setIsOpenRejectCancelRequestModal(true);
      setRejectCancelRequestModalType("order");
    }
  };

  const rejectRequestHandler = () => {
    if (!rejectReason.reason && rejectReason.reason.trim() == "") {
      setRejectReason((prev) => ({ ...prev, showError: true }));
      return;
    }

    if (rejectRequestModalType == "cancel") {
      if (rejectCancelRequestModalType == "order") {
        requestOrderCancelRequestAction("POST", "order/order-action", {
          id,
          status: "rejected",
          cancellation_reject_reason: rejectReason.reason,
        });
      } else if (rejectCancelRequestModalType == "item") {
        requestItemCancelRequestAction("POST", "order/item-action", {
          id: rejectCancelRequestItemId,
          status: "rejected",
          cancellation_reject_reason: rejectReason.reason,
        });
      }
    } else if (rejectRequestModalType == "return") {
      if (rejectCancelRequestModalType == "order") {
        requestOrderCancelRequestAction("POST", "order/order-return-action", {
          id,
          status: "rejected",
          return_reject_reason: rejectReason.reason,
        });
      } else if (rejectCancelRequestModalType == "item") {
        requestItemCancelRequestAction("POST", "order/item-return-action", {
          id: rejectCancelRequestItemId,
          status: "rejected",
          return_reject_reason: rejectReason.reason,
        });
      }
    }
  };

  const showCancelReasonModalCloseHandler = () => {
    setShowCancelReasonModal(false);
    setCancelOtherReasonSelected({
      status: false,
      reason: "",
      showError: false,
    });
    setSelectedReason(null);
    setCancelItemsModal([]);
    setCancelModalType(null);
  };

  const rejectCancelRequestModalCloseHandler = () => {
    setIsOpenRejectCancelRequestModal(false);
    setRejectCancelRequestModalType(null);
    setRejectCancelRequestItemId(null);
    setRejectRequestModalType(null);
    setRejectReason({ reason: "", showError: false });
  };

  const orderStatusUpdateHandler = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change status of this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestOrderStatusUpdate("PUT", "order/order-status", {
          id,
          status: nextOrderStatus.value,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const closeRefundModalHandler = () => {
    setIsRefundModalOpen(false);
    setRefundData({
      type: null,
      refundType: null,
      id: null,
      item_id: null,
    });
  };

  const onSubmitRefundHandler = (data) => {
    const { refund_amount } = data;

    requestRefundAmount("POST", "order/refund-amount", {
      refund_amount,
      ...refundData,
    });
  };

  const confirmPickupHandler = (type, id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Want to change status of this ${type}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestConfirmPickup("POST", "order/order-mark-pickup", {
          id,
          type,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Order"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/order/all", name: "Back To Orders" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div
              style={{
                display: "block",
              }}
              className="card-body px-0"
            >
              <div className="tab-content px-10">
                {orders && (
                  <div className="row mb-5">
                    <div
                      className={
                        orders?.bankAccountDetails ? "col-md-3" : "col-md-6"
                      }
                    >
                      <div className="orderDltInfo">
                        <h6>ORDER DETAIL</h6>
                        <p>
                          Order Id:{" "}
                          {orders.order_number ? orders.order_number : "-"}
                        </p>
                        {
                          <p>
                            Transaction Id:{" "}
                            {orders.transaction_id
                              ? orders.transaction_id
                              : "-"}
                          </p>
                        }

                        {orders?.created_at ? (
                          <p>
                            Order Time:{" "}
                            {orders.created_at
                              ? moment(orders.created_at).format(
                                  date_time_format
                                )
                              : "-"}
                          </p>
                        ) : (
                          ""
                        )}

                        {orders?.paymentMethod ? (
                          <p>
                            Payment Method:{" "}
                            {orders.paymentMethod ? orders.paymentMethod : "-"}
                          </p>
                        ) : (
                          ""
                        )}

                        {orders?.deliveryFee ? (
                          <p>
                            Delivery Fee:{" "}
                            {orders.deliveryFee
                              ? orders.deliveryFee + " " + "USD"
                              : "-"}
                          </p>
                        ) : (
                          ""
                        )}

                        {orders?.tax_amount ? (
                          <p>
                            Tax:{" "}
                            {orders.tax_amount
                              ? orders.tax_amount + " " + "USD"
                              : "-"}
                          </p>
                        ) : (
                          ""
                        )}

                        {orders?.sub_total ? (
                          <p>
                            Sub Total:{" "}
                            {orders.sub_total
                              ? orders.sub_total + " " + "USD"
                              : "-"}
                          </p>
                        ) : (
                          ""
                        )}
                        {orders?.delivery_fee ? (
                          <p>
                            Delivery Charges:{" "}
                            {orders.delivery_fee
                              ? orders.delivery_fee + " " + "USD"
                              : "-"}
                          </p>
                        ) : (
                          ""
                        )}

                        {orders?.coupon_discount ? (
                          <p>
                            Offer & Discounts:{" "}
                            {orders?.coupon_discount
                              ? "-" + orders?.coupon_discount + " " + "USD"
                              : "-"}
                            {orders?.coupon_code && (
                              <p className="offer-title">
                                ({orders?.coupon_code})
                              </p>
                            )}
                          </p>
                        ) : (
                          ""
                        )}

                        {orders?.total || orders?.total === 0 ? (
                          <p>Total: {orders.total.toFixed(2) + " " + "USD"}</p>
                        ) : (
                          ""
                        )}

                        {orders?.cancellationReason ? (
                          <p>
                            Cancellation Reason:{" "}
                            <span className="text-danger">
                              {orders.cancellationReason
                                ? orders.cancellationReason
                                : "-"}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {orders?.bankAccountDetails && (
                      <div className="col-6 col-md-3">
                        <div className="orderDltInfo">
                          <h6>BANK ACCOUNT DETAILS</h6>
                          {orders?.bankAccountDetails?.bankName ? (
                            <p>
                              Bank Name:{" "}
                              {orders?.bankAccountDetails?.bankName
                                ? orders?.bankAccountDetails?.bankName
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}

                          {orders?.bankAccountDetails?.accountName ? (
                            <p>
                              Account Name:{" "}
                              {orders?.bankAccountDetails?.accountName
                                ? orders?.bankAccountDetails?.accountName
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}

                          {orders?.bankAccountDetails?.iban ? (
                            <p>
                              IBAN:{" "}
                              {orders?.bankAccountDetails?.iban
                                ? orders?.bankAccountDetails?.iban
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}

                          {orders?.bankAccountDetails?.currency?.label ? (
                            <p>
                              Currency:{" "}
                              {orders?.bankAccountDetails?.currency?.label
                                ? orders?.bankAccountDetails?.currency?.label
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}

                          {orders?.bankAccountDetails?.swift ? (
                            <p>
                              Swift:{" "}
                              {orders?.bankAccountDetails?.swift
                                ? orders?.bankAccountDetails?.swift
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}

                    {orders?.customer_name ||
                    orders?.customer_email ||
                    orders?.customer_phone ? (
                      <div className="col-6 col-md-3">
                        <div className="orderDltInfo">
                          <h6>DELIVER TO.</h6>
                          {orders?.customer_name ? (
                            <p>
                              Name:{" "}
                              {orders.customer_name
                                ? orders.customer_name
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}

                          {orders?.customer_email ? (
                            <p>
                              Email:{" "}
                              {orders.customer_email
                                ? orders.customer_email
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                          {orders?.customer_phone ? (
                            <p>
                              Phone:{" "}
                              {orders.customer_phone
                                ? orders.customer_phone
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {
                      <div className="col-6 col-md-3">
                        <div className="orderDltInfo">
                          <h6>DELIVERY ADDRESS</h6>
                          <p>
                            Name:{" "}
                            {orderAddress?.first_name +
                              " " +
                              orderAddress?.last_name}
                          </p>

                          <p>
                            Phone: +{orderAddress?.phone_number_code}-
                            {orderAddress?.phone_number}
                          </p>

                          <p>
                            Address: {orderAddress?.address_1},{" "}
                            {orderAddress?.address_2
                              ? orderAddress?.address_2
                              : ""}
                          </p>

                          <p>City: {orderAddress?.city}</p>
                          <p>State: {orderAddress?.state}</p>

                          <p>Country: {orderAddress?.country}</p>

                          {orders?.deliveryAddress?.pinCode ? (
                            <p>
                              Zip Code:{" "}
                              {orders?.deliveryAddress?.pinCode
                                ? orders?.deliveryAddress?.pinCode
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                          {/* <p>
                           State:{" "}
                           {orders?.deliveryAddress?.state
                             ? orders?.deliveryAddress?.state
                             : "-"}
                         </p> */}
                        </div>
                      </div>
                    }

                    {orders?.deliveryAddress ? (
                      <div className="col-12 my-2">
                        <div className="orderDltInfo">
                          <h6>Shipping Details</h6>
                          {orders?.shippingMethod ? (
                            <p>
                              Shipping Method:{" "}
                              {orders.shippingMethod
                                ? orders.shippingMethod
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                          {orders?.shippingPrice ||
                          orders?.shippingPrice === 0 ? (
                            <p>
                              Shipping Price:{" "}
                              {orders.shippingPrice
                                ? orders.shippingPrice + " " + "USD"
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="d-flex align-items-center">
                            Shipping Status:{" "}
                            {orders?.shippingStatus === "Success"
                              ? orders?.shippingStatusMessage && (
                                  <span
                                    className="success_subText"
                                    style={{
                                      backgroundColor: "rgb(152, 251, 152)",
                                    }}
                                  >
                                    {orders?.shippingStatusMessage}
                                  </span>
                                )
                              : orders?.shippingStatus === "Failed"
                              ? orders?.shippingStatusMessage && (
                                  <span
                                    className="success_subText"
                                    style={{
                                      backgroundColor: "rgb(250, 160, 160)",
                                    }}
                                  >
                                    {orders.shippingStatusMessage}
                                  </span>
                                )
                              : ""}
                          </p>
                          {orders?.shippingWaybillFileUrl && (
                            <a
                              href={orders?.shippingWaybillFileUrl}
                              className="btn btn-success"
                              target="_blank"
                            >
                              Download Label
                            </a>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div className="order-inner-product">
                  <div className="all-track-box">
                    {orders?.status?.length > 0 && (
                      <div className="trackMyoder">
                        <h3 className="orderSubTitle">Order Tracking:</h3>
                        <div className="order_statusFlex">
                          <div className="trackStatus">
                            <div className="progress_stepBox">
                              {orders?.status?.length > 0 &&
                                orders?.status?.map((val) => {
                                  return (
                                    <div
                                      className={`progressStep ${
                                        val.is_active == 1 ? "active" : ""
                                      }`}
                                      key={val.id}
                                    >
                                      <div className="iconProgressStep">
                                        <span>
                                          <svg
                                            width="8"
                                            height="6"
                                            viewBox="0 0 8 6"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0.8125 2.99994L2.935 5.12244L7.1875 0.877441"
                                              stroke="white"
                                              stroke-width="1.3"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                      <div className="step-title tooltip_flexDiv">
                                        {val.status}
                                        {val.order_status == "Cancelled" && (
                                          <TooltipComponent
                                            value={orders.cancel_reason}
                                          />
                                        )}
                                        {val.order_status ==
                                          "cancel_request_received" && (
                                          <TooltipComponent
                                            value={orders.cancel_reason}
                                          />
                                        )}
                                        {val.order_status ==
                                          "return_request_received" && (
                                          <TooltipComponent
                                            value={orders.reject_reason}
                                          />
                                        )}
                                        {val.order_status ==
                                          "return_rejected" &&
                                          orders.return_reject_reason && (
                                            <TooltipComponent
                                              value={
                                                orders.return_reject_reason
                                              }
                                            />
                                          )}
                                        {val.order_status == "rejected" &&
                                          val.is_active == 1 && (
                                            <TooltipComponent
                                              value={
                                                orders.cancellation_reject_reason
                                              }
                                            />
                                          )}
                                      </div>
                                      <div className="step-timestamp">
                                        {val.is_active == 1
                                          ? moment(val.updated_at).format(
                                              "DD/MMM/YY hh:mm a"
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          {showNextButtonArray.includes(
                            orders?.current_status
                          ) ? (
                            <div className="btn-group" role="group">
                              {nextOrderStatus.value && (
                                <button
                                  type="button"
                                  className="btn btn-primary active"
                                  onClick={orderStatusUpdateHandler}
                                >
                                  {nextOrderStatus.key}
                                </button>
                              )}
                              {["order_placed", "order_confirmed"].includes(
                                orders?.current_status
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setShowCancelReasonModal(true);
                                    setCancelItemsModal(orderItems);
                                    setCancelModalType("order");
                                  }}
                                >
                                  Cancel
                                </button>
                              )}
                            </div>
                          ) : orders?.current_status ==
                            "cancel_request_received" ? (
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className="btn btn-primary active"
                                onClick={() => {
                                  orderCancalRequestActionHadnler(
                                    "accepted",
                                    "cancel"
                                  );
                                }}
                              >
                                Accept
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  orderCancalRequestActionHadnler(
                                    "rejected",
                                    "cancel"
                                  );
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          ) : ["Cancelled", "accepted", "refund"].includes(
                              orders?.current_status
                            ) &&
                            orders?.transaction_id &&
                            orders?.cancel_reason ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                setIsRefundModalOpen(true);
                                setRefundData({
                                  type: "order",
                                  refundType: "cancel",
                                  id: orders?.id,
                                  item_id: null,
                                });
                              }}
                            >
                              Refund
                            </button>
                          ) : ["pick_up_confirmed", "refund"].includes(
                              orders?.current_status
                            ) &&
                            orders?.transaction_id &&
                            orders?.reject_reason ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                setIsRefundModalOpen(true);
                                setRefundData({
                                  type: "order",
                                  refundType: "return",
                                  id: orders?.id,
                                  item_id: null,
                                });
                              }}
                            >
                              Refund
                            </button>
                          ) : orders?.current_status ==
                            "return_request_received" ? (
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className="btn btn-primary active"
                                onClick={() => {
                                  orderCancalRequestActionHadnler(
                                    "accepted",
                                    "return"
                                  );
                                }}
                              >
                                Accept
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  orderCancalRequestActionHadnler(
                                    "rejected",
                                    "return"
                                  );
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          ) : orders?.current_status == "return_accepted" ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                confirmPickupHandler("order", orders?.id);
                              }}
                            >
                              Confirm Pick Up
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table_nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th clas>Item</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Total Price</th>
                        <th>Cancel / Return Request</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.orderItems?.length > 0 &&
                        orders?.orderItems.map((item) => (
                          <tr>
                            <td>
                              <strong>{item.name || "-"}</strong>
                              <br />
                              Size: {item.size}
                              <br />
                              Colors: {item.color}
                              <br />
                              Amount: {"USD"} {item.price}
                              <br />
                              Total Amount: {"USD"} {item.total_price}
                              <br />
                              <span className="cancel-reason">
                                {item?.cancel_reason
                                  ? `Cancel Reason: ${item?.cancel_reason}`
                                  : item?.reject_reason
                                  ? `Reject Reason: ${item?.reject_reason}`
                                  : ""}
                              </span>
                            </td>
                            <td>{item.quantity}</td>
                            <td>
                              {"USD"} {item.price}
                            </td>
                            <td>
                              {"USD"} {item.total_price}
                            </td>
                            <td>
                              {item?.cancel_status
                                ? CancelObj[item?.cancel_status]
                                : item?.return_status
                                ? CancelObj[item?.return_status]
                                : "No Request"}
                            </td>

                            {/* {item?.cancel_status == null &&
                            item?.return_status == null ? (
                              <td>
                                {["order_placed", "order_confirmed"].includes(
                                  orders?.current_status
                                ) && (
                                  <button
                                    type="button"
                                    class="order_borderBtn btn btn-danger"
                                    onClick={() => {
                                      setShowCancelReasonModal(true);
                                      setCancelItemsModal([item]);
                                      setCancelModalType("item");
                                    }}
                                  >
                                    <span>Cancel</span>{" "}
                                  </button>
                                )}
                              </td>
                            ) : orders?.current_status !=
                                "cancel_request_received" &&
                              item?.cancel_status == "request_received" ? (
                              <td>
                                {console.log(item, "one")}

                                <button
                                  type="button"
                                  class="order_borderBtn btn btn-primary mr-2"
                                  onClick={() => {
                                    itemCancalRequestActionHadnler(
                                      item.id,
                                      "accepted",
                                      "cancel"
                                    );
                                  }}
                                >
                                  <span>Accept</span>{" "}
                                </button>
                                <button
                                  type="button"
                                  class="order_borderBtn btn btn-primary "
                                  onClick={() => {
                                    itemCancalRequestActionHadnler(
                                      item.id,
                                      "rejected",
                                      "cancel"
                                    );
                                  }}
                                >
                                  <span>Reject</span>{" "}
                                </button>
                              </td>
                            ) : item?.return_status == "request_received" ? (
                              <td>
                                {console.log(item, "two")}

                                <button
                                  type="button"
                                  class="order_borderBtn btn btn-primary mr-2"
                                  onClick={() => {
                                    itemCancalRequestActionHadnler(
                                      item.id,
                                      "accepted",
                                      "return"
                                    );
                                  }}
                                >
                                  <span>Accept</span>{" "}
                                </button>
                                <button
                                  type="button"
                                  class="order_borderBtn btn btn-primary "
                                  onClick={() => {
                                    itemCancalRequestActionHadnler(
                                      item.id,
                                      "rejected",
                                      "return"
                                    );
                                  }}
                                >
                                  <span>Reject</span>{" "}
                                </button>
                              </td>
                            ) : item?.cancel_status == "accepted" ? (
                              <td>
                                {console.log(item, "three")}

                                <button
                                  type="button"
                                  class="order_borderBtn btn btn-danger"
                                  onClick={() => {
                                    setIsRefundModalOpen(true);
                                  }}
                                >
                                  <span>Refund</span>{" "}
                                </button>
                              </td>
                            ) : (
                              <></>
                            )} */}

                            {["order_placed", "order_confirmed"].includes(
                              orders?.current_status
                            ) &&
                              item.cancel_status == null && (
                                <td>
                                  <button
                                    type="button"
                                    class="order_borderBtn btn btn-danger"
                                    onClick={() => {
                                      setShowCancelReasonModal(true);
                                      setCancelItemsModal([item]);
                                      setCancelModalType("item");
                                    }}
                                  >
                                    <span>Cancel</span>{" "}
                                  </button>
                                </td>
                              )}

                            {![
                              "cancel_request_received",
                              "accepted",
                              "rejected",
                              "refund",
                            ].includes(orders?.current_status) &&
                              item?.cancel_status == "request_received" && (
                                <td>
                                  <button
                                    type="button"
                                    class="order_borderBtn btn btn-primary mr-2"
                                    onClick={() => {
                                      itemCancalRequestActionHadnler(
                                        item.id,
                                        "accepted",
                                        "cancel"
                                      );
                                    }}
                                  >
                                    <span>Accept</span>{" "}
                                  </button>
                                  <button
                                    type="button"
                                    class="order_borderBtn btn btn-primary "
                                    onClick={() => {
                                      itemCancalRequestActionHadnler(
                                        item.id,
                                        "rejected",
                                        "cancel"
                                      );
                                    }}
                                  >
                                    <span>Reject</span>{" "}
                                  </button>
                                </td>
                              )}

                            {![
                              "return_request_received",
                              "return_accepted",
                              "return_rejected",
                              "pick_up_confirmed",
                              "refund",
                            ].includes(orders?.current_status) &&
                              item?.return_status == "request_received" && (
                                <td>
                                  <button
                                    type="button"
                                    class="order_borderBtn btn btn-primary mr-2"
                                    onClick={() => {
                                      itemCancalRequestActionHadnler(
                                        item.id,
                                        "accepted",
                                        "return"
                                      );
                                    }}
                                  >
                                    <span>Accept</span>{" "}
                                  </button>
                                  <button
                                    type="button"
                                    class="order_borderBtn btn btn-primary "
                                    onClick={() => {
                                      itemCancalRequestActionHadnler(
                                        item.id,
                                        "rejected",
                                        "return"
                                      );
                                    }}
                                  >
                                    <span>Reject</span>{" "}
                                  </button>
                                </td>
                              )}

                            {![
                              "cancel_request_received",
                              "accepted",
                              "rejected",
                              "refund",
                            ].includes(orders?.current_status) &&
                              ["accepted", "refund"].includes(
                                item?.cancel_status
                              ) &&
                              orders?.transaction_id && (
                                <td>
                                  <button
                                    type="button"
                                    className="order_borderBtn btn btn-danger"
                                    onClick={() => {
                                      setIsRefundModalOpen(true);
                                      setRefundData({
                                        type: "item",
                                        refundType: "cancel",
                                        id: orders.id,
                                        item_id: item.id,
                                      });
                                    }}
                                  >
                                    <span>Refund</span>
                                  </button>
                                </td>
                              )}

                            {![
                              "return_request_received",
                              "return_accepted",
                              "return_rejected",
                              "pick_up_confirmed",
                              "refund",
                            ].includes(orders?.current_status) &&
                              ["pickup_expected", "refund"].includes(
                                item?.return_status
                              ) &&
                              orders?.transaction_id && (
                                <td>
                                  <button
                                    type="button"
                                    className="order_borderBtn btn btn-danger"
                                    onClick={() => {
                                      setIsRefundModalOpen(true);
                                      setRefundData({
                                        type: "item",
                                        refundType: "return",
                                        id: orders.id,
                                        item_id: item.id,
                                      });
                                    }}
                                  >
                                    <span>Refund</span>
                                  </button>
                                </td>
                              )}

                            {["accepted"].includes(item?.return_status) && (
                              <td>
                                <button
                                  type="button"
                                  className="order_borderBtn btn btn-primary"
                                  onClick={() => {
                                    confirmPickupHandler("item", item?.id);
                                  }}
                                >
                                  <span>Confirm Pick Up</span>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {false && (
                  <div className="order-inner-product">
                    {orderItems?.length > 0 &&
                      orderItems.map((order) => (
                        <div className="all-track-box">
                          {order?.nextStatus?.length > 0 &&
                            order?.nextStatus.map((val) => {
                              return val?.type === "return_accepted" ||
                                val?.type === "return_rejected" ? (
                                <button
                                  className={"btn btn-success m-2 "}
                                  onClick={() => {
                                    setOrderStatusType(val.type);
                                    val.isReasonRequired
                                      ? handleRejectStatus(val.type, order?._id)
                                      : handleStatusChange(
                                          val.type,
                                          order?._id,
                                          order
                                        );
                                  }}
                                >
                                  {val.name}
                                </button>
                              ) : (
                                <div className="dropdown d-inline">
                                  <button
                                    className="dropdown-toggle btn btn-success dropdown-toggle mr-2 w125"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    {val.name}
                                  </button>

                                  <ul className="dropdown-menu">
                                    {order?.status?.length > 0 &&
                                      order?.status
                                        ?.filter((stat) => !stat.completed)
                                        .map((stat) => (
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="#"
                                              onClick={() => {
                                                setOrderStatusType(
                                                  stat?.status
                                                );
                                                val.isReasonRequired
                                                  ? handleRejectStatus(
                                                      stat?.status,
                                                      order?._id
                                                    )
                                                  : handleStatusChange(
                                                      stat?.status,
                                                      order?._id,
                                                      order
                                                    );
                                              }}
                                            >
                                              {stat?.name}
                                            </Link>
                                          </li>
                                        ))}
                                  </ul>
                                </div>
                              );
                            })}
                        </div>
                      ))}
                  </div>
                )}

                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showCancelReasonModal}
        ariaHideApp={false}
        className="model_block"
        onRequestClose={showCancelReasonModalCloseHandler}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cancel Order</h5>
              <button
                type="button"
                onClick={showCancelReasonModalCloseHandler}
                className="modalCloseIcon ms-auto"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M9.16992 14.8302L14.8299 9.17017"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M14.8299 14.8302L9.16992 9.17017"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-left">
                <div className="row">
                  {cancelItemsModal.map((item) => (
                    <div className="col-lg-12">
                      <div className="order_dltModal">
                        <div className="orderUserImg">
                          <img src={`${API.PORT}/${item?.image}`} alt="" />
                        </div>
                        <div className="order_userDlt">
                          <h3 className="order_proName">{item.name}</h3>
                          <div className="product_info">
                            <span className="pro_size">
                              <span>
                                Size : <strong>{item.size} | </strong>
                                Colors : <strong> {item.color} |</strong> QTY :{" "}
                                <strong> {item.quantity}</strong>
                              </span>
                            </span>
                          </div>
                          <div className="valueTextPrice">USD {item.price}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="cancellation_reasonBox border-top">
                  <h4 className="order_title" id="cancellation_Reason">
                    Cancellation Reason
                  </h4>
                  <ul className="cancel_reasonItems">
                    {cancelReasons.map((data) => (
                      <li key={data.id}>
                        <div className="custom-checkbox d-flex">
                          <input
                            type="radio"
                            name="cancel_reason"
                            id={`cancelCheckID-${data.id}`}
                            onChange={(e) => {
                              setCancelOtherReasonSelected((prev) => ({
                                reason: "",
                                status: false,
                                showError: false,
                              }));
                              setSelectedReason(data.id);
                            }}
                          />
                        </div>
                        <p className="order_text">{data.code}</p>
                      </li>
                    ))}

                    <li>
                      <div className="custom-checkbox d-flex">
                        <input
                          type="radio"
                          name="cancel_reason"
                          id="cancelCheckID6"
                          onChange={(e) => {
                            setCancelOtherReasonSelected((prev) => ({
                              ...prev,
                              status: e.target.checked,
                              showError: false,
                            }));
                            setSelectedReason(null);
                          }}
                        />
                      </div>
                      <p className="order_text">Other</p>
                    </li>
                  </ul>
                  {cancelOtherReasonSelected.status && (
                    <div className="form-group mb-0" id="reasonTextarea">
                      <label className="order_subTitle">
                        Write Your Reason
                      </label>
                      <textarea
                        className="form-control input-textarea"
                        placeholder="write here..."
                        rows={4}
                        id="customReason"
                        name="custom_reason"
                        onChange={(e) => {
                          setCancelOtherReasonSelected((prev) => ({
                            ...prev,
                            reason: e.target.value,
                            showError: false,
                          }));
                        }}
                        value={cancelOtherReasonSelected.reason}
                      />
                    </div>
                  )}
                  <div
                    id="customReasonError"
                    style={{
                      color: "red",
                      display: cancelOtherReasonSelected.showError
                        ? ""
                        : "none",
                    }}
                  >
                    Please write your reason for cancellation.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{ justifyContent: "center" }}>
              <button
                class="order_borderBtn btn btn-danger"
                onClick={cancelOrderHandler}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Order cancel reject modal */}
      <Modal
        isOpen={isOpenRejectCancelRequestModal}
        ariaHideApp={false}
        className="model_block"
        onRequestClose={rejectCancelRequestModalCloseHandler}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Reject {rejectRequestModalType} request{" "}
              </h5>
              <button
                type="button"
                onClick={rejectCancelRequestModalCloseHandler}
                className="modalCloseIcon ms-auto"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M9.16992 14.8302L14.8299 9.17017"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M14.8299 14.8302L9.16992 9.17017"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-left">
                <div className="cancellation_reasonBox">
                  <div className="form-group mb-0" id="reasonTextarea">
                    <label className="order_subTitle">Write Your Reason</label>
                    <textarea
                      className="form-control input-textarea"
                      placeholder="write here..."
                      rows={4}
                      id="customReason"
                      name="custom_reason"
                      onChange={(e) => {
                        setRejectReason(() => ({
                          reason: e.target.value,
                          showError: false,
                        }));
                      }}
                      value={rejectReason.reason}
                    />
                  </div>

                  <div
                    id="customReasonError"
                    style={{
                      color: "red",
                      display: rejectReason.showError ? "" : "none",
                    }}
                  >
                    Please write your reason for {rejectRequestModalType}.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{ justifyContent: "center" }}>
              <button
                class="order_borderBtn btn btn-danger"
                onClick={rejectRequestHandler}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Refund Modal */}
      <Modal
        isOpen={isRefundModalOpen}
        ariaHideApp={false}
        className="model_block"
        onRequestClose={closeRefundModalHandler}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Refund </h5>
              <button
                type="button"
                onClick={closeRefundModalHandler}
                className="modalCloseIcon ms-auto"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M9.16992 14.8302L14.8299 9.17017"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M14.8299 14.8302L9.16992 9.17017"
                    stroke="#686972"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-left">
                <form onSubmit={handleSubmit(onSubmitRefundHandler)}>
                  <div className="cancellation_reasonBox">
                    <div className="form-group mb-0" id="reasonTextarea">
                      <h6>
                        Amount Refundable {"USD"} :{" "}
                        {+orders?.total - +orders?.refund_amount}{" "}
                      </h6>
                      <label className="order_subTitle">
                        Refund Amount ({"USD"})
                      </label>
                      <br />
                      <input
                        {...register("refund_amount", {
                          required: "Refund amount must be entered",
                          pattern: {
                            value: /^-?\d*\.?\d+$/,
                            message: "Please select a valid number.",
                          },
                          min: {
                            value: 0,
                            message: "Amount should not be less then 0.",
                          },
                          max: {
                            value: +orders?.total - +orders?.refund_amount,
                            message: `This amount should not greter then ${
                              +orders?.total - +orders?.refund_amount
                            }`,
                          },
                        })}
                      />
                    </div>
                    {errors?.refund_amount && (
                      <div
                        id="customReasonError"
                        style={{
                          color: "red",
                        }}
                      >
                        {errors.refund_amount.message}
                      </div>
                    )}
                  </div>
                  <div className="mt-5">
                    <button
                      class="order_borderBtn btn btn-primary"
                      type="submit"
                    >
                      Refund
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewOne;
