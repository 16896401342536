import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  Textarea,
} from "../Form/Form";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, errors, register, required, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Add = () => {
  const { languages } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const { response, request } = useRequest();

  useEffect(() => {
    document.title = "Add Seo Page - Single Vendor";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Seo Page has been added successfully.");
      history.push("/seo-pages");
    }
  }, [response]);

  const onSubmit = (data) => {
    const formData = new FormData();

    if (data.og_image && data.og_image[0]) {
      formData.append("og_image", data.og_image[0]);
    }

    const subData = {
      page_id: data[`page_id`],
      page_name: data[`page_name`],
      title: data[`title`],
      meta_description: data[`meta_description`],
      meta_keywords: data[`meta_keywords`],
      twitter_card: data[`twitter_card`],
      twitter_site: data[`twitter_site`],
      og_url: data[`og_url`],
      og_type: data[`og_type`],
      og_title: data[`og_title`],
      og_description: data[`og_description`],
      meta_chronological: data[`meta_chronological`],
    };

    formData.append("data", JSON.stringify(subData));

    request("POST", "seo-page", formData);
  };

  const seoInputFields = [
    {
      Component: Input,
      label: "Page Id",
      name: "page_id",
      isRequired: true,
      registerFields: {
        required: true,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Page Name",
      name: "page_name",
      isRequired: true,
      registerFields: {
        required: true,
      },
      type: "text",
    },

    {
      Component: Input,
      label: "Title",
      name: "title",
      isRequired: true,
      registerFields: {
        required: true,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Meta Description",
      name: "meta_description",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Meta Keywords",
      name: "meta_keywords",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Twitter Card",
      name: "twitter_card",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Twitter Site",
      name: "twitter_site",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Og Url",
      name: "og_url",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Og Type",
      name: "og_type",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Og Title",
      name: "og_title",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Og Description",
      name: "og_description",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Meta Chronological",
      name: "meta_chronological",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Og Image",
      name: "og_image",
      isRequired: false,
      registerFields: {
        required: false,
      },
      type: "file",
      inputData: {
        accept: "image/*",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Seo Page"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/seo-pages", name: "Back To Seo Pages" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <div className="row">
                  {seoInputFields.map((Input, index) => (
                    <Input.Component
                      key={index}
                      {...Input}
                      errors={errors}
                      register={register}
                    />
                  ))}
                </div>
              </div>
              <SubmitButton
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                name="Submit"
                pxClass="px-10"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add;
