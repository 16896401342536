import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Moment from "moment";

import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  ReactSelectInput,
  ReactSelectInputTwo,
} from "../Form/Form";
import { FILE_MAX_SIZE, generateRandomString } from "../../util/helper";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, errors, register, required, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const ckEditorRef = useRef();
  const { id } = props.match.params;
  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    control,
    setError,
    reset,
  } = useForm();

  const [categoryHierarchyList, setCategoryHierarchyList] = useState([]);
  const [offerImage, setOfferImage] = useState();
  const { request: reqCategoryHierarchy, response: resCategoryHierarchy } =
    useRequest();
  const { response: responseGetSection, request: requestGetSection } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    document.title = "Edit-Offer - Single Vendor";
    reqCategoryHierarchy("GET", `category/activeCategoriesHierarchy`);
    requestGetSection("GET", `offer/${id}`);
  }, []);

  useEffect(() => {
    if (!resCategoryHierarchy) return;
    const data = resCategoryHierarchy.categories.map((category) => {
      return {
        label: category.category_hierarchy,
        value: category.category_level3_id,
      };
    });
    setCategoryHierarchyList(data);
  }, [resCategoryHierarchy]);

  useEffect(() => {
    if (!responseGetSection || !categoryHierarchyList.length) return;
    const { offer } = responseGetSection;

    if (!offer) {
      history.push("/offer");
      return;
    }

    const resetObj = {};
    const langDataIdsObj = {};

    resetObj.name = offer.name;
    resetObj.start_time = Moment(offer.start_time).format("YYYY-MM-DD");
    resetObj.end_time = Moment(offer.end_time).format("YYYY-MM-DD");
    if (offer.mininum_product_price)
      resetObj.mininum_product_price = offer.mininum_product_price;
    if (offer.maxinum_product_price)
      resetObj.maxinum_product_price = offer.maxinum_product_price;

    resetObj.offer_percentage = offer.offer_percentage;

    const selectedCategory = categoryHierarchyList.find(
      (h) => h.value == offer.category_id
    );

    resetObj.category = selectedCategory;

    reset(resetObj);
    setOfferImage(offer.image);
  }, [responseGetSection, categoryHierarchyList]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push(`/offer`);
    }
  }, [response]);

  const checkImageValidatiy = (image) => {
    if (image && !image.type.startsWith("image/")) {
      toast.error("Please select a valid image file.");
      return false;
    }

    if (image && image.size > FILE_MAX_SIZE) {
      toast.error("image size must be less than 5MB.");
      return false;
    }
    return true;
  };

  const onSubmitNew = (data) => {
    console.log("data:::::::::::::", data);

    const { start_time, end_time } = data;

    if (start_time && end_time) {
      if (Moment(start_time).isAfter(end_time)) {
        toast.error("Please enter valid date range");
        return;
      }
    }

    const selectedImageFile = data["image"]?.[0];

    if (!checkImageValidatiy(selectedImageFile)) return;

    const subData = {
      name: data.name,
      start_time: data.start_time,
      end_time: data.end_time,
      offer_percentage: data.offer_percentage,
      category_id: data.category.value,
    };

    subData.mininum_product_price = data.mininum_product_price || null;

    subData.maxinum_product_price = data.maxinum_product_price || null;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("image", selectedImageFile);
    formData.append("data", JSON.stringify(subData));

    request("PUT", "offer", formData);
  };

  const InputGroup1 = [
    {
      Component: Input,
      label: "Name",
      name: "name",
      registerFields: {
        required: true,
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Start Time",
      name: "start_time",
      isDate: true,

      registerFields: {
        required: true,
      },
      clearErrors,
      control,
      type: "date",
      min: new Date().toISOString().split("T")[0],
    },
    {
      Component: Input,
      label: "End Time",
      name: "end_time",
      isDate: true,
      clearErrors,
      control,
      registerFields: {
        required: true,
      },

      type: "date",
      min: new Date().toISOString().split("T")[0],
    },
    {
      Component: Input,
      label: "Minimum Product Price",
      name: "mininum_product_price",
      registerFields: {
        required: false,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Maximum Product Price",
      name: "maxinum_product_price",
      registerFields: {
        required: false,
        min: 0,
        validate: {
          check: (value) => {
            const price = getValues("mininum_product_price");
            if (!value || !price) return true;
            return (
              value * 1 >= price * 1 ||
              "Max Price must be greter than or equal to Min price"
            );
          },
        },
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Offer Percentage",
      name: "offer_percentage",
      registerFields: {
        required: true,
        min: 0,
        max: 100,
      },
      type: "number",
    },
    {
      Component: ReactSelectInputTwo,
      label: "Category",
      name: "category",
      registerFields: {
        required: true,
      },
      options: categoryHierarchyList,
      isMultiple: false,
      control: control,
      colClass: "col-xl-12",
    },
    {
      Component: Input,
      label: "Offer image",
      name: "image",
      registerFields: {
        required: false,
        //onChange: handleImageChange,
      },
      isRequired: false,
      inputData: {
        accept: "image/*",
      },
      type: "file",
      image: offerImage,
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Offer"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/offer", name: "Offers" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {/* input */}

                  <div className="row">
                    {InputGroup1.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Update"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Edit;
